.dashboard-actions-container {
  --w: 125px;
  --n: 3;
  --clamp: 100%/(var(--n) + 1) + 0.1%, var(--w), 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(var(--clamp)), 1fr));
  gap: 10px;

  img {
    image-rendering: -webkit-optimize-contrast;
  }
}

.attachments-container {
  --w: 125px;
  --n: 3;
  --clamp: 100%/(var(--n) + 1) + 0.1%, var(--w), 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(var(--clamp)), 1fr));
  gap: 10px;
}
