@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

body {
  /* font: 400 1rem 'Hindi', sans-serif; */
  background: #f5f6fa;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  overflow: auto;
  max-height: calc(100vh - 64px);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Hind', sans-serif;
}

.app-sidebar {
  @apply bg-white;
  transition: all 300ms;
  z-index: 1;
  box-shadow: 5px 0 5px 0 #00000014;
  height: 100vh;

  .ant-menu-item {
    @apply flex items-center;
  }
}

.text-brand {
  --tw-text-opacity: 1;
  color: rgba(43, 63, 134, var(--tw-text-opacity)) !important;
}

.login-form-item {
  .ant-form-item-label {
    padding: 0;

    > label {
      @apply text-sm font-medium;

      &::before {
        content: '' !important;
        margin: 0 !important;
      }
    }
  }
}

.anticon-check,
.ant-steps .anticon-close {
  position: absolute;
  top: 6px !important;
  right: -6px;
}

.ant-picker-time-panel-cell-disabled {
  display: none !important;
}

.ant-image-preview-img {
  display: inline;
}

.dev {
  background-color: #ede49f!important;
}
